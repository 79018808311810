<template>
  <div>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell :title="item.title" v-for="item in list" :key="item.title">
          <van-button
            type="info"
            size="small"
            @click="onclick($event, item.title)"
            >{{ item.names }}</van-button
          >
        </van-cell>
      </van-list>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    async onLoad() {
      this.list = [];
      let res = await this.$api.fn_user_project_get();
      res = res.data;
      if (typeof res == "string") {
        res = res.replace("\\/g", "");
        res = eval("(" + res + ")");
      }
      res = res.data;
      let addition =
        (parseFloat(localStorage.getItem("agent_addition")) + 100) / 100;
      if (isNaN(addition)) {
        addition = 1;
      }
      res.forEach((element) => {
        let name = "取消收藏";
        this.list.push({
          title:
            "[" +
            element.id +
            "]  " +
            element.name +
            "----价格:" +
            parseFloat((element.price*addition).toFixed(4)),
          names: name,
        });
      });
      this.finished = true;
      // 加载状态结束
      this.loading = false;
    },
    async onclick(e, index) {
      if (e.target.innerText === "已经取消") {
        this.$toast("请不要重复点击");
        return;
      }
      let ret = await this.$api.fn_user_project_pop(
        index.substring(index.indexOf("[") + 1, index.indexOf("]"))
      );
      ret = ret.data;
      if (ret.message === "ok") {
        this.$toast("取消成功");
        e.target.innerText = "已经取消";
      } else {
        this.$toast(ret.message);
        e.target.innerText = ret.message;
      }
    },
  },
};
</script>

<style scoped>
.serch {
  width: 100%;
  position: fixed;
  top: 5px;
  left: 0;
  z-index: 99;
}
.btn {
  width: 2rem;
  text-align: center;
  background: #f0eae7;
}
.list {
  margin-top: 0px;
}
</style>
